import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import 'assets/css/styles.scss';

import routes, { renderRoutes } from "routers/routes";
import { AuthProvider } from "./contexts/JWTAuthContext";
//--- Redux

import { viVN } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ConfirmModal from "components/ConfirmModal";
import LoadingFullScreen from "components/LoadingFullScreen";
import ToastNotification from "components/ToastNotification";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from "react";
import viLocale from 'date-fns/locale/vi';
import enLocale from 'date-fns/locale/en-US';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from "moment";
import { useTranslation } from "react-i18next";


const theme = createTheme({
    ...viVN,
    typography: {
        allVariants: {
            fontFamily: 'Inter'
        }
    }
});

function App() {
    const { i18n } = useTranslation();
    const [localeDate, setlocaleDate] = useState<any>(viLocale);
    useEffect(() => {
        if (i18n.language !== 'en') {
            moment.locale('vi');
            setlocaleDate(viLocale);
        } else {
            moment.locale('en');
            setlocaleDate(enLocale);
        }
    }, [i18n.language]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeDate} >
                <ThemeProvider theme={theme}>
                    <Router>
                        <AuthProvider>{renderRoutes(routes)}</AuthProvider>
                    </Router>
                </ThemeProvider>
            </LocalizationProvider>
            <ConfirmModal />
            <ToastNotification />
            <LoadingFullScreen />
        </>
    );
}

export default App;
