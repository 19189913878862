import { createSlice } from '@reduxjs/toolkit';
import REDUX_SLICE_NAMES from 'consts/redux-slice-names';
import { useDispatch } from 'react-redux';

export interface Permissions {
    actionCode: string | null;
    name?: string | null;
    resourceCode: string | null;
}
export interface IGetPermissionResponse {
    permissions: Permissions[];
}

interface InitialStateType {
    permissions: Permissions[];
}

const initialState: InitialStateType = {
    permissions: [],
};

export const permissionSlice: any = createSlice({
    name: REDUX_SLICE_NAMES.PERMISSION,
    initialState,
    reducers: {
        setPermissionsAction: (state, { payload }) => {
            state.permissions = payload;
        }
    }
});

export const { setPermissionsAction } = permissionSlice.actions;

export const usePermissionState = () => {
    const dispatch = useDispatch();
    const setPermission = (data: any) => {
        dispatch(setPermissionsAction(data));
    };

    return {  setPermission };
};

export default permissionSlice.reducer;
