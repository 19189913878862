export enum ArticlesStatus {
    Published = "Published",
    AwaitingApproval = "AwaitingApproval",
    Rejected = "Rejected",
    UnPublished = "UnPublished",
    Draft = "Draft",
    WaitingPublish = "WaitingPublish",
}

export enum ProductCountry {
    VietNam = "VietNam",
    Australia = "Australia",
    Brazil = "Brazil",
    America = "America",
}