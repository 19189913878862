import SidebarCustom from "layouts/SideBar";
import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Hidden, Paper } from '@mui/material';
import Header from "layouts/Header";
import { useGetOpenDrawerSideBarState } from "redux/openDrawerSideBar";
import SideBarNav from "layouts/SideBarNav";

const useStyles = makeStyles({
    mainContainer: {
        paddingTop: 85,
        left: 0,
        position: 'relative',
        transition: 'all 0.3s ease-in-out',
        padding: '20px 20px 0 4px'
    },
    paperContainer: {
        height: '95%',
        borderRadius: 'unset !important',
        boxShadow: 'unset !important',
        backgroundColor: '#FFFFFF !important'
    },
    mainContainerNav: {
        marginTop: 68,
        left: 0,
        position: 'relative',
        transition: 'all 0.2s ease-in-out',
        padding: 24
    },
});

type MainLayoutProps = {
    children: React.ReactNode;
};

const MainLayout = (props: MainLayoutProps) => {
    const { children } = props;
    const classes = useStyles();
    const { isOpenSideBar } = useGetOpenDrawerSideBarState();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 142);
    const [windowHeightNav, setWindowHeightNav] = useState(window.innerHeight - 150);
    const updateHeight = () => {
        setWindowHeight(window.innerHeight - 142);
        setWindowHeightNav(window.innerHeight - 150);
    };

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    });

    return (
        <>
            <Hidden mdDown>
                <div style={{minHeight: window.innerHeight, backgroundColor: '#FFFFFF' }}>
                    <Header />
                    <div className={'page-wrapper ' + classes.mainContainer} style={{ minHeight: windowHeight, marginLeft: isOpenSideBar ? 315 : 95 }}>
                        <Box className={classes.paperContainer} component={Paper}>
                            {children}
                        </Box>
                    </div>
                    <SidebarCustom />
                </div>
            </Hidden>
            <Hidden mdUp>
                <div style={{ backgroundColor: '#FFFFFF' }}>
                    <Header />
                    <div className={'page-wrapper ' + classes.mainContainerNav} style={{ minHeight: windowHeightNav, marginBottom: 46}}>
                        <Box className={classes.paperContainer} component={Paper}>
                            {children}
                        </Box>
                    </div>
                    <SideBarNav />
                </div>
            </Hidden>
        </>
    );
};

export default MainLayout;
