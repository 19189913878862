import API_URLS from "consts/api-url";
import axios from "./axios";


export const login = (params: any) => {
  return axios.post(API_URLS.LOGIN, params);
};

export const getCurrentUser = () => {
  return axios.get(API_URLS.GET_CURRENT_USER);
};

export const requestForgotPassword = (email: string) => {
  return axios.post(`${API_URLS.REQUEST_FORGOT_PASSWORD}?Email=${email}`);
};

export const updateUserProfile = (body: any) => {
  return axios.put(API_URLS.USER_PROFILE, body)
};

export const changePassword = (body: any) => {
  return axios.put(API_URLS.CHANGE_PASSWORD, body);
};

export const forgotChangePassword = (params: any) => {
  return axios.post(API_URLS.FORGOT_CHANGE_PASSWORD, undefined, { params }); 
};