import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { COLORS } from "consts/colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetCustomAccordionState } from "redux/accordion/accordion";
import { NonDecorationLink } from "styled/sidebar";
import { SideBarMenu } from "../type";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useSetConfirmModalState } from "redux/confirmModal/confirmModalSlice";
import { useSelector } from "react-redux";
import { useSetCheckingChangesState } from "redux/checkingChanges";

interface ListItemProps {
  showText: boolean;
  data: SideBarMenu;
  selected?: boolean;
}

const useStyles = makeStyles(() => ({
  itemButtonMini: {
    display: 'flex !important',
    justifyContent: 'center !important'
  }
}))

const ListItemButtonWithLink = ({ showText, data, selected }: ListItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openConfirmModal } = useSetConfirmModalState();
  const { setCheckingChanges } = useSetCheckingChangesState();
  const isChangeForm = useSelector((state: any) => state.checkingChanges?.isChange);
  const {setAccordionField} = useSetCustomAccordionState();

  const handleCheckFormChanges = (href: string) => {
    if (isChangeForm) {
      openConfirmModal({
        title: 'Cảnh báo',
        message: 'Bạn có chắc chắn muốn rời khỏi trang này không? Dữ liệu bạn đã nhập sẽ không được lưu.',
        isOpen: true,
        cancelBtnLabel: 'Hủy',
        okBtnLabel: 'Đồng ý',
        isCloseCase: false,
        onOk: () => {
          onNavigate(href)
        }
      });
    } else {
      // onNavigate(href);
    }
  };

  const onNavigate = (href: string) => {
    setCheckingChanges({ isChange: false });
    navigate(href, { replace: false });
  };

  return (
    <NonDecorationLink to={isChangeForm ? "#" : data.href as string} onClick={() => { handleCheckFormChanges(data.href as any) }}>
      <ListItemButton selected={selected} sx={{
        backgroundColor: selected ? `${COLORS.BACKGROUND_BUTTON} !important` : '#FFFF !important',
        margin: '0 10px',
        padding: '10px 10px',
        borderRadius: '8px',
        width: showText ? 'auto' : '57px'
      }} className={classNames({[classes.itemButtonMini]: !showText})}>
        <ListItemIcon
          sx={{
            mr: showText ? 1 : 0,
            justifyContent: {
              xs: "flex-start",
              sm: "center"
            },
            minWidth: '25px !important'
          }}
        >
          {selected ? data.iconActive : data.icon}
        </ListItemIcon>
        {showText && <ListItemText
          sx={{
            opacity: showText ? 1 : 0,
            color: selected ? COLORS.COLOR_SIDEBAR_ACTIVE : COLORS.COLOR_SIDEBAR,
            "& span": {
              fontWeight: selected ? '700 !important' : '500 !important'
            }
          }}
        >
          {t(data.title)}
        </ListItemText>}
      </ListItemButton>
    </NonDecorationLink>
  );
}

export default ListItemButtonWithLink;
