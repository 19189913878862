import { createSlice } from '@reduxjs/toolkit';
import REDUX_SLICE_NAMES from 'consts/redux-slice-names';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';

interface HighlightModel {
    highlight: any;
}

const initialState : HighlightModel = {
    highlight: null,
};

export const highlightSlice = createSlice({
    name: REDUX_SLICE_NAMES.HIGHLIGHT,
    initialState,
    reducers: {
      setHighlightState: (state: HighlightModel = initialState, action) => {
        state.highlight = action.payload;
      }
    },
  });
  
  const { setHighlightState } = highlightSlice.actions;
  
  export const useGetHighlightState = () => useAppSelector((state: RootState) => state[highlightSlice.name]) as HighlightModel;
  
  export const useSetCustomHighlightState = () => {
    const dispatch = useAppDispatch();
    return {
        setHightlightField: (value: any) => {
            dispatch(setHighlightState(value));
          },
    };
  };
  
  export default highlightSlice;
