import { ReactComponent as FlagVi } from 'assets/icons/FlagVn.svg';
import { ReactComponent as FlagEn } from 'assets/icons/FlagEn.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as IconDashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as IconUser } from 'assets/icons/user-sidebar.svg';
import { ReactComponent as IconProduct } from 'assets/icons/product.svg';
import { ReactComponent as IconNews } from 'assets/icons/news-sidebar.svg';
import { ReactComponent as IconEmail } from 'assets/icons/email-templates.svg';
import { ReactComponent as IconContact } from 'assets/icons/contact-sidebar.svg';
import { ReactComponent as IconBanner } from 'assets/icons/icon-banner.svg';
import { ReactComponent as IconIntroduce } from 'assets/icons/icon-introduce.svg';
import { ReactComponent as IconIntroduceActive } from 'assets/icons/introduce-active.svg';
import { ReactComponent as IconDashboardActive } from 'assets/icons/dashboard-active.svg';
import { ReactComponent as IconUserActive } from 'assets/icons/user-active.svg';
import { ReactComponent as IconProductActive } from 'assets/icons/product-active.svg';
import { ReactComponent as IconNewsActive } from 'assets/icons/news-active.svg';
import { ReactComponent as IconContactActive } from 'assets/icons/contact-active.svg';
import { ReactComponent as IconBannerActive } from 'assets/icons/banner-active.svg';
import { ReactComponent as Password } from 'assets/icons/icon-password.svg';
import { ReactComponent as IconUserLogin } from 'assets/icons/user-login.svg';
import { ReactComponent as IconEmailActive } from 'assets/icons/email-active.svg';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { ReactComponent as IconDelete } from 'assets/icons/icon-delete.svg';
import { ReactComponent as Nodata } from 'assets/icons/logo-no-data.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close.svg';
import { ReactComponent as AddIcon } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconCheckUpList } from 'assets/icons/checkup-list.svg';
import { ReactComponent as UnCheckBoxIcon } from 'assets/icons/unCheckBoxIcon.svg';
import { ReactComponent as CheckBoxIcon } from 'assets/icons/checkBoxIcon.svg';
import { ReactComponent as IconCheckUpListWhite } from 'assets/icons/icon-checkup-list-white.svg';
import { ReactComponent as IconScrollTop } from 'assets/icons/scroll-top.svg';
import { ReactComponent as IconFolder } from 'assets/icons/icon-folder.svg';
import { ReactComponent as IconUploadFile } from 'assets/icons/icon-upload-file.svg';
import { ReactComponent as IconInfo } from 'assets/icons/icon-info.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { ReactComponent as IconClosed } from 'assets/icons/icon-closed.svg';
import { ReactComponent as IconEye } from 'assets/icons/icon-view.svg';
import { ReactComponent as IconCalendar } from 'assets/icons/icon-calendar.svg';
import { ReactComponent as IconDownload } from 'assets/icons/arrow-download.svg';
import { ReactComponent as IconUnpublished } from 'assets/icons/unPublishIcon.svg';
import { ReactComponent as IconPublish } from 'assets/icons/icon-publish.svg';
import { ReactComponent as IconBell } from 'assets/icons/icon-bell-notification.svg';
import { ReactComponent as IconLogo } from 'assets/icons/logo.svg';
import { ReactComponent as IconOrder } from 'assets/icons/icon-order.svg';
import { ReactComponent as IconOrderActive } from 'assets/icons/icon-order-active.svg';
import { ReactComponent as IconBack } from 'assets/icons/icon-back.svg';
import { ReactComponent as IconSave } from 'assets/icons/icon-save.svg';
import { ReactComponent as IconAddDesc } from 'assets/icons/icon-add-descr.svg';
import { ReactComponent as IconAddDisableDesc } from 'assets/icons/icon-add-disable-descr.svg';
import { ReactComponent as IconRmDesc } from 'assets/icons/icon-rm-desc.svg';
import { ReactComponent as IconUpload } from 'assets/icons/icon-upload.svg';
import {ReactComponent as IconAddImg} from 'assets/icons/icon-add-image.svg'

export const Icons = {
  FlagVi,
  FlagEn,
  ArrowDown,
  IconDashboard,
  IconUser,
  IconProduct,
  IconNews,
  IconEmail,
  IconContact,
  IconBanner,
  IconIntroduce,
  IconIntroduceActive,
  IconDashboardActive,
  IconUserActive,
  IconProductActive,
  IconNewsActive,
  IconContactActive,
  IconBannerActive,
  Password,
  IconUserLogin,
  IconEmailActive,
  IconEdit,
  IconDelete,
  Nodata,
  Search,
  CloseIcon,
  AddIcon,
  IconCheckUpList,
  UnCheckBoxIcon,
  CheckBoxIcon,
  IconCheckUpListWhite,
  IconScrollTop,
  IconFolder,
  IconUploadFile,
  IconInfo,
  IconCheck,
  IconClosed,
  IconEye,
  IconCalendar,
  IconDownload,
  IconUnpublished,
  IconPublish,
  IconBell,
  IconLogo,
  IconOrder,
  IconOrderActive,
  IconBack,
  IconSave,
  IconAddDesc,
  IconRmDesc,
  IconUpload,
  IconAddDisableDesc,
  IconAddImg
};
