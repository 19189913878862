import { List } from "@mui/material";
import ListItemCollapseWithLink from "layouts/SideBar/components/ListItemCollapseWithLinl";


const MenuMobile = (props: any) => {
    const { menuSub } = props;

    return <>
        <List disablePadding sx={{ flexGrow: 1, overflowY: 'auto' }} className="sidebar-inner">
            {menuSub?.map((item: any) => {
                return (
                    <ListItemCollapseWithLink
                        key={item.title}
                        data={item}
                        showText={true}
                    />
                );
            })}
        </List></>
}

export default MenuMobile;