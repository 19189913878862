import { ERROR_API_MESSAGE } from 'consts/error-message';
import get from 'lodash/get';
import isString from 'lodash/isString';
import moment from 'moment';
import { UserInfoReduxType } from 'redux/userInfo/types';
import { SyntheticEvent } from 'react';

export const dateFormat = 'DD/MM/YYYY';
export const dateFormatResquestChart = 'YYYY-MM-DD';
export const dateFormatMonth = 'MM/DD/YYYY';
export const formatDateChart = 'DD/MM/YY';
export const dateTimeFormat = 'DD/MM/YYYY HH:mm';
export const convertdateTimeFormat = 'MM/DD/YYYY HH:mm';
export const formatDateTime = 'YYYY-MM-DDTHH:mm:ss';
export const dateParamFormat = ["YYYY-MM-DD", "DDMMYYYY", "DD-MM-YYYY", "DD/MM/YYYY"];

export const getErrorMessage = (response: any, message = '', mesgApi = true) => {
  if (isString(response)) return response || ERROR_API_MESSAGE[3];
  const errorMessage = mesgApi ? get(response, 'errorType', 3) : get(response, 'errorMessage');
  const getErrorMessage = mesgApi ? ERROR_API_MESSAGE?.[errorMessage] : errorMessage;
  const translatedMessage = getErrorMessage || ERROR_API_MESSAGE[3];
  return message || translatedMessage || errorMessage;
};

export const convertBreakToHtml = (text: string) => {
  return text && text.replace(/\r?\n/g, '<br/>');
};

export const formatDateTimeZone = (date: string, fomat?: string) => {
  if (date) {
    return moment(new Date(date).toISOString().split('T')[0]).format(fomat || dateFormat);
  }
  return '';
};

export const detectUserResponse = (userResponse: any) => {
  let result: UserInfoReduxType = {
    id: userResponse?.id || null,
    email: userResponse?.email || null,
    fullName: userResponse?.fullName || null,
    phoneNumber: userResponse?.phoneNumber || null,
    roles: userResponse?.role || null,
    isActive: userResponse?.isActive || null,
    birthDay: userResponse?.birthDay || null,
    avatar: userResponse?.avatar || null
  };

  return result;
};

export const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const handleErrorImage = (e: SyntheticEvent<HTMLImageElement, Event>, fallbackImage: string) => {
  e.currentTarget.onerror = null;
  e.currentTarget.src = fallbackImage;
};

export const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const stripHTML = (html: string) => {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.innerHTML?.includes('</figure>') ? doc.body.innerHTML : doc.body.textContent || '';
};

export const arrangeArray = (firstArray: string[], secondArray: string[]) => {
  let arrangedArray = [];
  for (let i = 0; i < firstArray.length; i++) {
    if (secondArray.includes(firstArray[i])) {
      arrangedArray.push(firstArray[i]);
    }
  }
  return arrangedArray;
};

export const downloadFile = (file: string) => {
  fetch(`${process.env.REACT_APP_IMG_URL}${file}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = `${file.substring(file.lastIndexOf('/') + 1)}`;
      alink.click();
    });
  });
};

export const VietNameseRegex = /^[^\u00C0-\u1EF9]+$/i;

export const convertToString = (obj: any, field?: any) => {
  const _obj: any = {};
  if (!obj) return;
  Object.keys(obj)
    .sort()
    .map((x: any) => {
      _obj[x] = obj[x];
      if (typeof obj[x] == 'string') {
        _obj[x] = obj[x] ? obj[x].trim() : '';
      }
      if (obj[x] == null || obj[x] == undefined) {
        _obj[x] = '';
      }
      if (field && !!field.find((y: any) => y.name == x)) {
        const { format } = field.find((y: any) => y.name == x);
        _obj[x] = moment(obj[x]).format(format);
      }
    });
  return JSON.stringify(_obj);
};

export const formatDateHasTimeTimeZone = (date: string, fomat?: string) => {
  if (date) {
    return new Date(
      moment(date)
        .utc()
        .format(convertdateTimeFormat || fomat)
    );
  }
  return '';
};

export const formatDateHasTimeTimeZoneOffset = (date: string, formatDate?: string) => {
  if (date) {
    return formatDate ? moment(date, dateParamFormat).format(formatDate) : moment(date, dateParamFormat).toDate();
  }
  return '';
};

export const compareImageSunEditor = (text: string, importFile?: boolean) => {
  if (!text) return '';
  text = text.replace(/data-rotate="[^"]*"/g, '');
  text = text.replace(/data-proportion="[^"]*"/g, '');
  text = text.replace(/data-rotatex="[^"]*"/g, '');
  text = text.replace(/data-rotatey="[^"]*"/g, '');
  text = text.replace(/data-size="[^"]*"/g, '');
  text = text.replace(/data-align="[^"]*"/g, '');
  text = text.replace(/data-index="[^"]*"/g, '');
  text = text.replace(/data-percentage="[^"]*"/g, '');
  text = text.replace(/data-file-name="[^"]*"/g, '');
  text = text.replace(/data-origin="[^"]*"/g, '');
  text = text.replace(/data-file-size="[^"]*"/g, '');
  text = text.replace(/origin-size="[^"]*"/g, '');
  text = text.replace(/\s+/g, '');
  text = text.replace(/style="[^"]*"/gi, "");
  text = text.replace(/class="[^"]*"/gi, "");
  // text = text.replace(/(<([^>]+)>)/ig, "");
  text = text.replace(/<(\w+)\b[^>]*><\/\1>/g, "");
  if (importFile) {
    text = text.replace(/<div[^>]*>/g, '');
    text = text.replace(/<\/div>/g, '');
  }
  return text;
};
