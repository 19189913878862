import API_URLS from "consts/api-url"
import axiosInstance from "./axios"


export interface LookUpRolesModel {
    id: string;
    name: string;
    description: string;
}

export const getRoles = (queryParams: any) => {
    return axiosInstance.get(API_URLS.GET_ROLES, { params: queryParams })
}

export const getDetailRoleId = (id: string) => axiosInstance.get(`${API_URLS.GET_DETAIL_ROLE}/${id}`);

export const createRole = (body: any) => {
    return axiosInstance.post(API_URLS.CREATE_ROLE, body)
}

export const updateRole = (id: string, body: any) => {
    return axiosInstance.put(`${API_URLS.UPDATE_ROLE}/${id}`, body)
}

export const deleteRole = (id: string) => {
    return axiosInstance.delete(`${API_URLS.DELETE_ROLE}/${id}`)
}

export const getLookUpRoles = () => {
    return axiosInstance.get(API_URLS.GET_LOOK_UP_ROLES)
}

export const getResourceActions = () => {
    return axiosInstance.get(API_URLS.GET_RESOURCE_ACTIONS)
}