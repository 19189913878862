import { createSlice } from "@reduxjs/toolkit";
import REDUX_SLICE_NAMES from "consts/redux-slice-names";
import { getErrorMessage } from "utils";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IToastNotificationAction, ToastNotificationReduxType } from "./types";
import { RootState } from "../store";

const initialState: ToastNotificationReduxType = {
  isOpen: false,
  notificationType: "success",
  message: "",
};

export const toastNotificationSlice = createSlice({
  name: REDUX_SLICE_NAMES.TOAST_NOTIFICATION,
  initialState,
  reducers: {
    setToastNotificationState: (state: ToastNotificationReduxType = initialState, action: IToastNotificationAction) => {
      if (action && action.payload) {
        for (let propertyName in initialState) {
          state = Object.assign(state, { [propertyName]: action.payload[propertyName as keyof ToastNotificationReduxType] });
        }
      }
    }
  }
});

const { setToastNotificationState } = toastNotificationSlice.actions;

export const useGetToastNotificationState = () => useAppSelector((state: RootState) => state[toastNotificationSlice.name]);

export const useSetToastNotificationState = () => {
  const dispatch = useAppDispatch();
  const showSuccessNotification = (message: string) => {
    dispatch(setToastNotificationState({ ...initialState, isOpen: true, message }));
  };
  const showErrorNotification = (message: string) => {
    dispatch(
      setToastNotificationState({
        ...initialState,
        notificationType: "error",
        isOpen: true,
        message
      })
    );
  };
  const closeNotification = () => {
    dispatch(setToastNotificationState({ ...initialState }));
  };
  const showErrorMessageFromResponse = (res: any, message = "", mesgApi = true) => {
    showErrorNotification(getErrorMessage(res, message, mesgApi));
  };
  return {
    showSuccessNotification,
    showErrorNotification,
    closeNotification,
    showErrorMessageFromResponse
  };
};



export default toastNotificationSlice;
