import React from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import classNames from 'classnames';
import BootstrapDialog from 'components/BootstrapDialog';
import Button from 'components/Button';

type CrudModalProps = {
  cancelBtnLabel: string;
  children: React.ReactNode;
  contentClassName: string;
  dialogProps: any;
  formTitle: string;
  handleClose: any;
  handleSave: (params?: any) => void;
  handleBtn: (params?: any) => void;
  handleSaveDraft: (params?: any) => void;
  handleReject: (params?: any) => void;
  isOpen: boolean;
  isForm?: boolean;
  moreActions: React.ReactNode;
  saveBtnLabel: string;
  publishBtnLabel: string;
  unPublishBtnLabel: string;
  loading: boolean;
  isConfirm?: boolean;
  isShowBtnClose?: boolean;
  iconBtnSave?: React.ReactNode;
  iconBtn?: React.ReactNode;
  btnLabel?: string;
  btncolor?: any;
  refModal?: any;
  classNameContainer?: any;
  iconTitle?: React.ReactNode;
  isNoAction?: boolean;
  noCheckingChanges?: boolean;
};

const CrudModal = (props: Partial<CrudModalProps>) => {
  const {
    isOpen,
    isForm,
    handleClose,
    handleSave,
    cancelBtnLabel = 'Hủy',
    saveBtnLabel = 'Lưu',
    formTitle,
    children,
    dialogProps,
    contentClassName,
    moreActions,
    isShowBtnClose = true,
    loading = false,
    iconBtnSave = <></>,
    isConfirm,
    handleBtn,
    iconBtn,
    btnLabel,
    btncolor = 'success',
    refModal,
    classNameContainer,
    iconTitle,
    noCheckingChanges,
    isNoAction = true
  } = props;

  return (
    <BootstrapDialog
      isOpen={isOpen}
      isForm={isForm}
      formTitle={formTitle}
      handleClose={handleClose}
      handleSave={handleSave}
      iconTitle={iconTitle}
      classNameContainer={classNameContainer}
      isConfirm={isConfirm}
      {...dialogProps}
    >
      <DialogContent
        className={classNames(contentClassName)}
        style={{ padding: '1rem', overflowX: 'hidden' }}
        dividers={!isConfirm}
        ref={refModal}
      >
        {children}
      </DialogContent>
      {isNoAction && (
        <DialogActions style={{ margin: '2px 16px 2px 0px' }}>
          {moreActions}
          {isShowBtnClose && (
            <Button color="inherit" onClick={handleClose} disabled={loading}>
              {cancelBtnLabel}
            </Button>
          )}
          {handleBtn && (
            <Button color={btncolor} onClick={handleBtn} loading={loading || false} startIcon={iconBtn}>
              {btnLabel}
            </Button>
          )}
          {handleSave && (
            <Button
              color="success"
              type="submit"
              onClick={handleSave}
              loading={loading || false}
              startIcon={iconBtnSave}
            >
              {saveBtnLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

export default CrudModal;
