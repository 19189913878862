import { Box, Button, Grid, SwipeableDrawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { default as classNames, default as clsx } from 'classnames';
import ROUTERS_PATHS from 'consts/router-paths';
import { SideBarMenu } from 'layouts/SideBar/type';
import { useEffect, useMemo, useState } from 'react';
import styles from "./styles.module.scss";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MenuMobile from './components/MenuMobile';
import { useSetCheckingChangesState } from 'redux/checkingChanges';
import { useSelector } from 'react-redux';
import { useSetConfirmModalState } from 'redux/confirmModal/confirmModalSlice';
import { ACTION_ROLE_CODE } from 'consts/permission';
import { compact } from 'lodash';
import { sideBarMenu } from 'layouts/SideBar';
import { COLORS } from 'consts/colors';

const useStyles = makeStyles(() => ({
    muiBottomNavigationRoot: {
        backgroundColor: '#FFFF',
        marginTop: 0,
        marginBottom: 0,
        '& .MuiButtonBase-root': {
            color: 'inherit',
            border: '0',
            cursor: 'pointer',
            margin: '0',
            display: 'inline-flex',
            outline: '0',
            padding: '0',
            position: 'relative',
            alignItems: 'center',
            userSelect: 'none',
            borderRadius: '0',
            verticalAlign: 'middle',
            MozAppearance: 'none',
            justifyContent: 'center',
            textDecoration: 'none',
            backgroundColor: 'transparent',
            WebkitAppearance: 'none',
            WebkitTapHighlightColor: 'transparent'
        },
        '& .MuiBottomNavigationAction-root': {
            width: '100%',
            height: 46,
            color: 'rgba(0, 0, 0, 0.54)',
            transition:
                'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        },
        '& .MuiBottomNavigationAction-root.Mui-before-selected': {
            backgroundColor: `${COLORS.BACKGROUND_BUTTON}`
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            color: '#3f51b5',
            backgroundColor: `${COLORS.BACKGROUND_BUTTON}`,
            borderRadius: '2.1875rem'
        },
        '& .MuiBottomNavigationAction-wrapper': {
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    }
}));

const SideBarNav = () => {
    const { permissions } = useSelector((state: any) => state.permission);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const { setCheckingChanges } = useSetCheckingChangesState();
    const isChangeForm = useSelector((state: any) => state.checkingChanges?.isChange);
    const navigate = useNavigate();
    const { openConfirmModal } = useSetConfirmModalState();
    type Anchor = 'top' | 'left' | 'bottom' | 'right';

    const [state, setState] = useState({
        right: false
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [menus, setMenu] = useState<SideBarMenu[]>(sideBarMenu?.filter((s) => !s.subMenu));
    const [menuSub, setMenuSub] = useState<SideBarMenu[]>(sideBarMenu?.filter((s) => s.subMenu));
    const indexActive = useMemo(() => {
        if (pathname === ROUTERS_PATHS.DASHBOARD) return 0;
        return menus.findIndex((m: any) => {
            setLoading(true);
            const isRouteMatch = m.href === pathname;
            if (isRouteMatch) return true;
            if (m.routeGroupPath && pathname.includes(m.routeGroupPath)) return true;
            return false;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, loading]);

    const handleCheckFormChanges = (href: string) => {
        if (isChangeForm) {
            openConfirmModal({
                title: 'Cảnh báo',
                message: 'Bạn có chắc chắn muốn rời khỏi trang này không? Dữ liệu bạn đã nhập sẽ không được lưu.',
                isOpen: true,
                cancelBtnLabel: 'Hủy',
                okBtnLabel: 'Đồng ý',
                isCloseCase: false,
                onOk: () => onNavigate(href),
            });
        }
    };

    const onNavigate = (href: string) => {
        setCheckingChanges({ isChange: false });
        navigate(href, { replace: false });
    };

    const onRemoveNull = (_menus: any = []) => {
        if (!_menus || _menus.length <= 0) return [];
        let menuFilter = _menus.filter((m: any) => {
            if (!m) return false;
            else if (!m.href && m.subMenu.length <= 0) return false;
            else return true;
        });

        return compact(
            menuFilter.map((m: any) => {
                if (m && m.subMenu && m.subMenu.length > 0) {
                    return {
                        ...m,
                        subMenu: onRemoveNull(m.subMenu)
                    };
                } else {
                    return m;
                }
            })
        );
    };

    const onLoopMenu = (_menus: any = []) => {
        if (!_menus || _menus.length <= 0) return [];

        return _menus.map((m: any) => {
            if (m.href) {
                const filterPermissions = (permissions || []).filter((p:any) => m.screenRole === p?.resourceCode);
                const isDisplay = (filterPermissions || []).some((action: any) => action.actionCode === ACTION_ROLE_CODE.View);
                const isDisplayConfirmArticles = (filterPermissions || []).some((action: any) => action.actionCode === ACTION_ROLE_CODE.ApprovedArticle);
                const isDisplayMenu = (m.href === ROUTERS_PATHS.CONFIRMATION_WAITLIST_ARTICLES) ? isDisplay && isDisplayConfirmArticles : isDisplay
                return isDisplayMenu
                   ? m
                   : null;
            } else {
                if (m.subMenu && m.subMenu.length > 0) {
                    return {
                        ...m,
                        subMenu: onLoopMenu(m.subMenu)
                    };
                } else {
                    return m;
                }
            }
        });
    };

    const handleCheckScreenByRole = () => {
        let _menus = onLoopMenu(sideBarMenu?.filter((s) => !s.subMenu));
        _menus = onRemoveNull(_menus);
        _menus = onRemoveNull(_menus);
        setMenu(_menus);

        let _menusSub = onLoopMenu(sideBarMenu?.filter((s) => s.subMenu));
        _menusSub = onRemoveNull(_menusSub);
        _menusSub = onRemoveNull(_menusSub);
        setMenuSub(_menusSub);
    }

    useEffect(() => {
        handleCheckScreenByRole();
    }, [permissions]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classNames(
                classes.muiBottomNavigationRoot,
                styles.sideBarContainer,
                styles.sideBarMobile,
            )}
        >
            {menus.map((m, i) => (
                <Grid key={i} item xs={2}>
                    <Link
                        to={isChangeForm ? "#" : m.href as string}
                        className={clsx('MuiButtonBase-root MuiBottomNavigationAction-root', {
                            'Mui-before-selected': indexActive === i,
                            'Mui-selected': indexActive === i
                        })}
                    >
                        <span className="MuiBottomNavigationAction-wrapper">
                            {indexActive === i ? (
                                <Box>{m.iconActive}</Box>
                            ) : (
                                <Box>{m.icon}</Box>
                            )}
                        </span>
                    </Link>
                </Grid>
            ))}
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={toggleDrawer('right', true)}>
                    <MenuIcon />
                </Button>
                <SwipeableDrawer
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                    onOpen={toggleDrawer('right', true)}
                >
                    <Box sx={{ background: '#FFFF', height: window.innerHeight }}>
                        <MenuMobile menuSub={menuSub} />
                    </Box>
                </SwipeableDrawer>
            </Grid>
        </Grid>
    )
}

export default SideBarNav;