import { createSlice } from "@reduxjs/toolkit";
import ELEMENT_IDS from "consts/element-ids";
import REDUX_SLICE_NAMES from "consts/redux-slice-names";
import { useAppDispatch, useAppSelector } from "../hooks";
import { RootState } from "../store";
import { ConfirmModalReduxType, ISetConfirmModalAction } from "./types";


const initialState: ConfirmModalReduxType = {
  isOpen: false,
  title: "Chú ý",
  message: "",
  cancelBtnLabel: "Đóng",
  okBtnLabel: "Tiếp tục",
  isCloseCase: false,
  btncolor: 'error',
  iconTitle: null,
  isDeleteConfirm: false,
  isNoAction: true,
}; 

const excludeFieldNames = ["message", "okBtnLabel", "title", "cancelBtnLabel", "btncolor", "iconTitle"];

export const confirmModalSlice = createSlice({
  name: REDUX_SLICE_NAMES.CONFIRM_MODAL,
  initialState,
  reducers: {
    setConfirmModalState: (state: ConfirmModalReduxType = initialState, action: ISetConfirmModalAction) => {
      if (action && action.payload) {
        for (let propertyName in initialState) {
          const isMessageClose = action.payload.isCloseCase && excludeFieldNames.includes(propertyName);
          if (!isMessageClose) {
            state = Object.assign(state, { [propertyName]: action.payload[propertyName as keyof ConfirmModalReduxType] });
          }
        }
      }
    },
  },
});

const { setConfirmModalState } = confirmModalSlice.actions;

export const useGetConfirmModalState = () => useAppSelector((state: RootState) => state[confirmModalSlice.name]);

interface IOpenConfirmModalParams extends ConfirmModalReduxType {
  onOk: () => void;
}

export const useSetConfirmModalState = () => {
  const dispatch = useAppDispatch();

  const openConfirmModal = (options: IOpenConfirmModalParams) => {
    const { onOk, ...stateOption } = options;
    const handleButton = document.getElementById(ELEMENT_IDS.CONFIRM_MODAL_OK_BUTTON);
    if (onOk && typeof onOk === "function" && handleButton) handleButton.click = onOk;

    dispatch(setConfirmModalState({ ...initialState, ...stateOption, isOpen: true }));
  };

  const closeConfirmModal = () => {
    const handleButton = document.getElementById(ELEMENT_IDS.CONFIRM_MODAL_OK_BUTTON);

    if (handleButton) handleButton.click = function () {};

    dispatch(setConfirmModalState({ ...initialState, isCloseCase: true }));
  };

  return {
    openConfirmModal,
    closeConfirmModal,
  };
};

export default confirmModalSlice;
