import { createSlice } from "@reduxjs/toolkit";
import REDUX_SLICE_NAMES from "consts/redux-slice-names";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";

interface InitialStateType {
  isOpenSideBar: boolean;
  isHoverOpenSideBar: boolean;
}

const initialState: InitialStateType = {
  isOpenSideBar: true,
  isHoverOpenSideBar: false,
};

export const openDrawerSideBarSlice = createSlice({
  name: REDUX_SLICE_NAMES.OPEN_DRAWER,
  initialState,
  reducers: {
    setIsOpenDrawer: (state: InitialStateType = initialState, action) => {
      const listProperties = Object.keys(state);
        Object.keys(action.payload).forEach((field) => {
          if (listProperties.includes(field)) {
            state[field as keyof InitialStateType] = action.payload[field as keyof InitialStateType] as never;
          }
        });
    },
  },
});

export const { setIsOpenDrawer } = openDrawerSideBarSlice.actions;

export const useGetOpenDrawerSideBarState = () => useAppSelector((state: RootState) => state[openDrawerSideBarSlice.name]) as InitialStateType;

export const useSetOpenDrawerSideBarState = () => {
  const dispatch = useDispatch();
  const setOpenDrawerSideBar = (fields: Partial<InitialStateType>) => {
    dispatch(setIsOpenDrawer(fields));
  };

  return { setOpenDrawerSideBar };
};

export default openDrawerSideBarSlice;
