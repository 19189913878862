import React, { Fragment, Suspense, lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import GuestGuard from 'components/GuestGuard';
import LoadingFullScreen from 'components/LoadingFullScreen';
import ROUTERS_PATHS from 'consts/router-paths';
import MainLayout from 'layouts/MainLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import Permission from 'components/Permission';
import { ACTION_ROLE_CODE, SCREEN_ROLE_CODE } from 'consts/permission';


interface IGuestGuardProps {
  children: React.ReactNode;
  routes: IRoutesState[];
}
export interface IRoutesState {
  exact?: boolean;
  path?: string;
  roles?: string[];
  guard?: React.FC<IGuestGuardProps>;
  layout?: React.FC<any>;
  component?: any;
  routes?: IRoutesState[];
}

export const renderRoutes = (routes: IRoutesState[]) => (
  <Suspense fallback={<LoadingFullScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard routes={routes}>
                <Permission role={route.roles}>
                  <Layout>{route.routes ? renderRoutes(route.routes) : <Component screenName={route.roles} />}</Layout>
                </Permission>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes: IRoutesState[] = [
  {
    exact: true,
    path: ROUTERS_PATHS.NOT_FOUND,
    component: lazy(() => import('pages/NotFound'))
  },
  {
    exact: true,
    path: ROUTERS_PATHS.AUTHORIZATION,
    component: lazy(() => import('pages/AuthorizationView'))
  },
  {
    guard: GuestGuard,
    path: ROUTERS_PATHS.LOGIN,
    component: lazy(() => import('pages/Login'))
  },
  {
    guard: GuestGuard,
    path: ROUTERS_PATHS.FORGOT_PASSWORD,
    component: lazy(() => import("pages/ForgotPassword")),
  },
  {
    path: ROUTERS_PATHS.RESET_PASSWORD,
    component: lazy(() => import("pages/ForgotChangePassword")),
  },
  {
    path: ROUTERS_PATHS.ALL,
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        path: ROUTERS_PATHS.DASHBOARD,
        component: lazy(() => import('pages/Dashboard')),
        roles: [SCREEN_ROLE_CODE.Dashboard]
      },
      {
        path: ROUTERS_PATHS.USER_MANAGEMENT_LIST_USERS,
        component: lazy(() => import('pages/UserManagement/ListUsers')),
        roles: [SCREEN_ROLE_CODE.Users]
      },
      {
        path: ROUTERS_PATHS.USER_MANAGEMENT_LIST_ROLES,
        component: lazy(() => import('pages/UserManagement/ListRoles')),
        roles: [SCREEN_ROLE_CODE.Roles]
      },
      {
        path: ROUTERS_PATHS.PRODUCT_MANAGEMENT,
        component: lazy(() => import('pages/ProductManagement')),
        roles: [SCREEN_ROLE_CODE.Products]
      },
      {
        path: ROUTERS_PATHS.PRODUCT_MANAGEMENT_CREATE_OR_UPDATE,
        component: lazy(() => import('pages/ProductManagement/components/AddEditProduct')),
        roles: [SCREEN_ROLE_CODE.Products]
      },
      {
        path: ROUTERS_PATHS.PRODUCT_MANAGEMENT_CREATE,
        component: lazy(() => import('pages/ProductManagement/components/AddEditProduct')),
        roles: [SCREEN_ROLE_CODE.Products]
      },
      {
        path: ROUTERS_PATHS.ORDER_MANAGEMENT_LIST_REQUEST,
        component: lazy(() => import('pages/OrderManagement/ListRequest')),
        roles: [SCREEN_ROLE_CODE.Orders]
      },
      {
        path: ROUTERS_PATHS.ORDER_MANAGEMENT_LIST_ORDER_EXCEL,
        component: lazy(() => import('pages/OrderManagement/ListOrdersFromExcel')),
        roles: [SCREEN_ROLE_CODE.Orders]
      },
      {
        path: ROUTERS_PATHS.LIST_ARTICLES_MANAGEMENT,
        component: lazy(() => import('pages/ArticleManagement')),
        roles: [SCREEN_ROLE_CODE.Articles]
      },
      {
        path: ROUTERS_PATHS.CONFIRMATION_WAITLIST_ARTICLES,
        component: lazy(() => import('pages/ArticleManagement')),
        roles: [SCREEN_ROLE_CODE.Articles, ACTION_ROLE_CODE.ApprovedArticle]
      },
      {
        path: ROUTERS_PATHS.BANNER_MANAGEMENT,
        component: lazy(() => import('pages/BannerManagemant')),
        roles: [SCREEN_ROLE_CODE.Banners]
      },
      {
        path: ROUTERS_PATHS.EMAIL_TEMPLATE,
        component: lazy(() => import('pages/EmailTemplate')),
        roles: [SCREEN_ROLE_CODE.EmailTemplate]
      },
      {
        path: ROUTERS_PATHS.CUSTOMER_FEEDBACK_MANAGEMENT,
        component: lazy(() => import('pages/CustomerFeedbackManagement')),
        roles: [SCREEN_ROLE_CODE.Contact]
      },
      {
        path: ROUTERS_PATHS.PROFILE,
        component: lazy(() => import('pages/Profile'))
      },
      {
        path: '*',
        component: () => <Navigate to={ROUTERS_PATHS.NOT_FOUND} replace />
      }
    ]
  }
];

export default routes;
