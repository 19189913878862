import { createSlice } from '@reduxjs/toolkit';
import REDUX_SLICE_NAMES from 'consts/redux-slice-names';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';

interface AccordionModel {
    itemAccordion: string;
}

const initialState : AccordionModel = {
  itemAccordion: '',
};

export const accordionSlice = createSlice({
    name: REDUX_SLICE_NAMES.ACCORDION,
    initialState,
    reducers: {
      setAccordionState: (state: AccordionModel = initialState, action) => {
        state.itemAccordion = action.payload;
      }
    },
  });
  
  const { setAccordionState } = accordionSlice.actions;
  
  export const useGetAccordionState = () => useAppSelector((state: RootState) => state[accordionSlice.name]) as AccordionModel;
  
  export const useSetCustomAccordionState = () => {
    const dispatch = useAppDispatch();
    return {
        setAccordionField: (accordion: string) => {
            dispatch(setAccordionState(accordion));
          },
    };
  };
  
  export default accordionSlice;
