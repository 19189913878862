import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { AppBar, Badge, Box, MenuItem, MenuList, Popover, Toolbar } from "@mui/material";
import ImageAvatar from 'assets/images/avatar-default.png';
import ELEMENT_IDS from "consts/element-ids";
import useAuth from "contexts/useAuth";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ROUTERS_PATHS from "consts/router-paths";
import MenuIcon from "@mui/icons-material/Menu";
import { useGetOpenDrawerSideBarState, useSetOpenDrawerSideBarState } from "redux/openDrawerSideBar";
import { useSelector } from "react-redux";
import { Icons } from "consts/svg-icons";

const Header = () => {
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const currentUser = useSelector((state: any) => state.userInfo);
  const navigate = useNavigate();
  const { isOpenSideBar } = useGetOpenDrawerSideBarState();
  const { setOpenDrawerSideBar } = useSetOpenDrawerSideBarState();
  const anchorElRef = useRef(null);

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const idAction = openMenu ? "simple-popover" : undefined;

  const handleClosePopover = () => {
    setOpenMenu(false);
  };

  const handleOpenProfile = () => {
    navigate(ROUTERS_PATHS.PROFILE);
    handleClosePopover();
  };

  const hidenSidebar = () => {
    setOpenDrawerSideBar({ isOpenSideBar: !isOpenSideBar as any });
  };

  const ImageSrc = currentUser?.avatar ? `${process.env.REACT_APP_IMG_URL}${currentUser.avatar}` : '';

  return (
    <AppBar className="appBar">
      <Toolbar className="toolBar toolBarClick" sx={{ marginLeft: isOpenSideBar ? "290px" : "80px" }}>
        <Box
          className="iconMenu"
          sx={{
            color: "#000",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            paddingTop: "5px",
            marginRight: "15px",
          }}
          onClick={hidenSidebar}
        >
          <MenuIcon sx={{ fontSize: 30 }} />
        </Box>
        <div id={ELEMENT_IDS.BREADCRUMB_CONTAINER}></div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            position: "absolute",
            right: "20px",
          }}
        >
          <Badge color="error" badgeContent={null} max={99}>
            <Icons.IconBell />
          </Badge>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            ref={anchorElRef}
            onClick={(e) => setOpenMenu(true)}
          >
            <div className="textContainer">
              <p className="userName">{currentUser?.fullName}</p>
            </div>
            <img
              src={ImageSrc || ImageAvatar}
              width={40}
              height={40}
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="avatarAdmin"
            />
          </Box>
        </Box>
        <Popover
          id={idAction}
          open={openMenu}
          anchorEl={anchorElRef.current}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuList>
            <MenuItem
              key="0"
              onClick={() => {
                handleOpenProfile();
              }}
            >
              {t("header.account")}
              <PersonIcon style={{ marginLeft: "10px" }} />
            </MenuItem>
            <MenuItem
              key="1"
              onClick={() => {
                signOut();
              }}
            >
              {t("header.logout")}
              <LogoutIcon color="error" sx={{ marginLeft: "10px" }} />
            </MenuItem>
          </MenuList>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
