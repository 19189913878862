import React from "react";

import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
    useGetToastNotificationState,
    useSetToastNotificationState,
} from "redux/toastNotification/toastNotificationSlice";
import { convertBreakToHtml } from "utils";

const Alert = React.forwardRef(function Alert(props: AlertProps, ref?: React.Ref<any>) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastNotification = () => {
    const { isOpen, message, notificationType} = useGetToastNotificationState();
    const { closeNotification } = useSetToastNotificationState();
    return (
        <>
            {!!isOpen ? (
                <Snackbar
                    open={!!isOpen}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={3000}
                    onClose={closeNotification}
                >
                    <Alert
                        onClose={closeNotification}
                        severity={notificationType as AlertColor}
                        sx={{ width: "100%", color: "white" , fontFamily: 'Inter !important'}}
                    >
                     <div dangerouslySetInnerHTML={{__html: convertBreakToHtml(message)}}/>
                    </Alert>
                </Snackbar>
            ) : (
                <></>
            )}
        </>
    );
};

export default ToastNotification;
