const REDUX_SLICE_NAMES = {
  LOADING_FULL_SCREEN: "loadingFullScreen",
  CONFIRM_MODAL: "confirmModal",
  TOAST_NOTIFICATION: "toastNotification",
  USER_INFO: "userInfo",
  OPEN_DRAWER: "openDrawer",
  ACCORDION: 'accordionTree',
  CHECKING_CHANGES: 'checkingChanges',
  PERMISSION: 'permission',
  HIGHLIGHT: 'highlight',
};

export default REDUX_SLICE_NAMES;
