import styles from "./styles.module.scss";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { COLOR_BUTTON } from "consts/statusCode";
import { useMemo } from "react";

const Button = (props: LoadingButtonProps) => {
  const { children, color, ...restProps } = props;
  let classNameByType = useMemo(() => {
    let _classNameByType = "";
    switch (color) {
      case COLOR_BUTTON.WARNING:
        _classNameByType = styles.warning;
        break;

      case COLOR_BUTTON.INHERIT:
        _classNameByType = styles.inherit;
        break;
      case COLOR_BUTTON.SUCCESS:
        _classNameByType = styles.success;
        break;
      case COLOR_BUTTON.ERROR:
        _classNameByType = styles.error;
        break;
      case COLOR_BUTTON.INFO:
        break;
      case COLOR_BUTTON.PRIMARY:
        _classNameByType = styles.primary;
        break;
      case COLOR_BUTTON.SECONDARY:
        break;

      default:
        break;
    }
    return _classNameByType;
  }, [color]);

  return <LoadingButton
    className={classNameByType}
    color={color}
    sx={{ textTransform: "unset !important" }}
    {...restProps}>
    {children}
  </LoadingButton>;
};

Button.defaultProps = {
  color: "primary",
  variant: "contained"
};

export default Button;
