import { ArticlesStatus } from "./enum";

export const COLOR_BUTTON = {
  WARNING: 'warning',
  INHERIT: 'inherit',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const STATUS_CHIP = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  WARNING: 'WARNING',
  DRAFT: 'Draft',
  UNPUBLISHED: 'UnPublished',
  AWAITING_APPROVAL: 'AwaitingApproval',
  REJECTED: 'Rejected',
  WAITING_PUBLISH: 'WaitingPublish',
  PUBLISHED: 'Published',
};

export const LIST_STATUS = [
  {
    label: 'Đang hoạt động',
    value: true,
    chipType: STATUS_CHIP.ACTIVE
  },
  {
    label: 'Không hoạt động',
    value: false,
    chipType: STATUS_CHIP.INACTIVE
  }
];


export const LIST_STATUS_ARTICLE = [
  {
    label: 'Đã xuất bản',
    value: ArticlesStatus.Published,
    chipType: STATUS_CHIP.ACTIVE
  },
  {
    label: 'Chờ duyệt',
    value: ArticlesStatus.AwaitingApproval,
    chipType: STATUS_CHIP.AWAITING_APPROVAL
  },
  {
    label: 'Từ chối',
    value: ArticlesStatus.Rejected,
    chipType: STATUS_CHIP.INACTIVE
  },
  {
    label: 'Nháp',
    value: ArticlesStatus.Draft,
    chipType: STATUS_CHIP.WARNING
  },
  {
    label: 'Chờ xuất bản',
    value: ArticlesStatus.WaitingPublish,
    chipType: STATUS_CHIP.WAITING_PUBLISH
  }
];