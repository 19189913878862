import { createSlice } from "@reduxjs/toolkit";
import REDUX_SLICE_NAMES from "consts/redux-slice-names";
import SESSION_KEYS from "consts/storage-keys";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { IUserInfoAction, UserInfoReduxType } from "./types";
import { RootState } from "redux/store";

const initialState: UserInfoReduxType = {
  id: null,
  email: null,
  fullName: null,
  phoneNumber: null,
  roles: null,
  birthDay: null,
  isActive: null,
  avatar: null
};

export const userInfoSlice = createSlice({
  name: REDUX_SLICE_NAMES.USER_INFO,
  initialState,
  reducers: {
    setUserInfoState: (state: UserInfoReduxType = initialState, action: IUserInfoAction) => {
      if (action && action.payload) {
        const userInfo = action.payload;
        for (let property in userInfo) {
          state = Object.assign(state, { [property]: action.payload[property as keyof UserInfoReduxType] });
        }
      }
    },
  },
});

export const { setUserInfoState } = userInfoSlice.actions;

export const useCurrentUser = () => {
  const dispatch = useAppDispatch();
  const setCurrentUser = (user: UserInfoReduxType) => {
    dispatch(setUserInfoState(user));
  };

  const getCurrentUserInfo = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAppSelector((state: RootState) => state[userInfoSlice.name]);

  const onLogout = () => {
    sessionStorage.removeItem(SESSION_KEYS.ACCESS_TOKEN);
    sessionStorage.removeItem(SESSION_KEYS.REFRESH_TOKEN);
    dispatch(setUserInfoState({ ...initialState }));
  };
  return { setCurrentUser, getCurrentUserInfo, onLogout };
};

export default userInfoSlice;
