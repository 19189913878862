import ROUTERS_PATHS from 'consts/router-paths';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetOpenDrawerSideBarState, useSetOpenDrawerSideBarState } from 'redux/openDrawerSideBar';
import { makeStyles } from '@mui/styles';
import ImgLogo from 'assets/icons/logo-admin.svg';
import ImgLogoMini from 'assets/images/logoMini-admin.png';
import { Drawer, DrawerHeader } from 'styled/sidebar';
import { List } from '@mui/material';
import ListItemCollapseWithLink from './components/ListItemCollapseWithLinl';
import ListItemButtonWithLink from './components/ListItemButtonWithLink';
import { Icons } from 'consts/svg-icons';
import { SideBarMenu } from './type';
import { useSelector } from 'react-redux';
import { ACTION_ROLE_CODE, SCREEN_ROLE_CODE } from 'consts/permission';
import { compact } from 'lodash';
import { useSetCheckingChangesState } from 'redux/checkingChanges';

const useStyles = makeStyles(() => ({
    drawerContainer: {
        '& .MuiDrawer-paper': {
            overflowY: 'hidden',
            borderRight: '1px solid #9FB0AA !important'
        }
    }
}));

export const sideBarMenu = [
    {
        key: 'dashboard',
        title: 'sideBar.homePage',
        icon: <Icons.IconDashboard />,
        iconActive: <Icons.IconDashboardActive />,
        href: ROUTERS_PATHS.DASHBOARD,
        screenRole: SCREEN_ROLE_CODE.Dashboard
    },
    {
        key: 'userManagement',
        title: 'sideBar.userManagement',
        icon: <Icons.IconUser />,
        iconActive: <Icons.IconUserActive />,
        subMenu: [
            {
                title: 'sideBar.userList',
                href: ROUTERS_PATHS.USER_MANAGEMENT_LIST_USERS,
                screenRole: SCREEN_ROLE_CODE.Users
            },
            {
                title: 'sideBar.listRoles',
                href: ROUTERS_PATHS.USER_MANAGEMENT_LIST_ROLES,
                screenRole: SCREEN_ROLE_CODE.Roles
            }
        ]
    },
    {
        key: 'productManagement',
        title: 'sideBar.productManagement',
        icon: <Icons.IconProduct />,
        iconActive: <Icons.IconProductActive />,
        href: ROUTERS_PATHS.PRODUCT_MANAGEMENT,
        screenRole: SCREEN_ROLE_CODE.Products
    },
    {
        key: 'orderManagement',
        title: 'Quản lý đơn hàng',
        icon: <Icons.IconOrder />,
        iconActive: <Icons.IconOrderActive />, 
        subMenu: [
            {
                title: 'Danh sách đơn đặt hàng',
                href: ROUTERS_PATHS.ORDER_MANAGEMENT_LIST_REQUEST,
                screenRole: SCREEN_ROLE_CODE.Orders
            },
            {
                title: 'Danh sách đơn hàng import',
                href: ROUTERS_PATHS.ORDER_MANAGEMENT_LIST_ORDER_EXCEL,
                screenRole: SCREEN_ROLE_CODE.Orders
            }
        ]
    },
    {
        key: 'newsManagement',
        title: 'sideBar.newsManagement',
        icon: <Icons.IconNews />,
        iconActive: <Icons.IconNewsActive />,
        subMenu: [
            {
                title: 'sideBar.listOfArticles',
                href: ROUTERS_PATHS.LIST_ARTICLES_MANAGEMENT,
                screenRole: SCREEN_ROLE_CODE.Articles
            },
            {
                title: 'sideBar.listForConfirmation',
                href: ROUTERS_PATHS.CONFIRMATION_WAITLIST_ARTICLES,
                screenRole: SCREEN_ROLE_CODE.Articles
            }
        ]
    },
    {
        key: 'bannerManagement',
        title: 'sideBar.bannerManagement',
        icon: <Icons.IconBanner />,
        iconActive: <Icons.IconBannerActive />,
        href: ROUTERS_PATHS.BANNER_MANAGEMENT,
        screenRole: SCREEN_ROLE_CODE.Banners
    },
    {
        key: 'emailTemplates',
        title: 'sideBar.emailTemplates',
        icon: <Icons.IconEmail />,
        iconActive: <Icons.IconEmailActive />,
        href: ROUTERS_PATHS.EMAIL_TEMPLATE,
        screenRole: SCREEN_ROLE_CODE.EmailTemplate
    },
    {
        key: 'contact',
        title: 'sideBar.contact',
        icon: <Icons.IconContact />,
        iconActive: <Icons.IconContactActive />,
        href: ROUTERS_PATHS.CUSTOMER_FEEDBACK_MANAGEMENT,
        screenRole: SCREEN_ROLE_CODE.Contact
    },
    // {
    //   key: 'introduce',
    //   title: "sideBar.introduce",
    //   icon: <Icons.IconIntroduce />,
    //   iconActive: <Icons.IconIntroduceActive />,
    //   href: 'introduce',
    // },
]

const Sidebar = (props: any) => {
    const navigate = useNavigate();
    const { permissions } = useSelector((state: any) => state.permission);
    const { isOpenSideBar, isHoverOpenSideBar } = useGetOpenDrawerSideBarState();
    const { setOpenDrawerSideBar } = useSetOpenDrawerSideBarState();
    const classes = useStyles();
    const location = useLocation();
    const pathname = location.pathname;
    const { setCheckingChanges } = useSetCheckingChangesState();


    const [menu, setMenu] = useState<SideBarMenu[]>(sideBarMenu);

    const handleMouseEnter = () => {
        !isOpenSideBar && setOpenDrawerSideBar({ isHoverOpenSideBar: true });
    };

    const handleMouseLeave = () => {
        !isOpenSideBar && setOpenDrawerSideBar({ isHoverOpenSideBar: false });
    };

    const onRemoveNull = (_menus: any = []) => {
        if (!_menus || _menus.length <= 0) return [];
        let menuFilter = _menus.filter((m: any) => {
            if (!m) return false;
            else if (!m.href && m.subMenu.length <= 0) return false;
            else return true;
        });

        return compact(
            menuFilter.map((m: any) => {
                if (m && m.subMenu && m.subMenu.length > 0) {
                    return {
                        ...m,
                        subMenu: onRemoveNull(m.subMenu)
                    };
                } else {
                    return m;
                }
            })
        );
    };
    
    const onLoopMenu = (_menus: any = []) => {
        if (!_menus || _menus.length <= 0) return [];

        return _menus.map((m: any) => {
            if (m.href) {
                const filterPermissions = (permissions || []).filter((p:any) => m.screenRole === p?.resourceCode);
                const isDisplay = (filterPermissions || []).some((action: any) => action.actionCode === ACTION_ROLE_CODE.View);
                const isDisplayConfirmArticles = (filterPermissions || []).some((action: any) => action.actionCode === ACTION_ROLE_CODE.ApprovedArticle);
                const isDisplayMenu = (m.href === ROUTERS_PATHS.CONFIRMATION_WAITLIST_ARTICLES) ? isDisplay && isDisplayConfirmArticles : isDisplay
                return isDisplayMenu
                   ? m
                   : null;
            } else {
                if (m.subMenu && m.subMenu.length > 0) {
                    return {
                        ...m,
                        subMenu: onLoopMenu(m.subMenu)
                    };
                } else {
                    return m;
                }
            }
        });
    };

    const handleCheckScreenByRole = () => {
        let _menus = onLoopMenu(sideBarMenu);
        _menus = onRemoveNull(_menus);
        _menus = onRemoveNull(_menus);
        setMenu(_menus);
    }

    const handleClickLogo = () => {
        setCheckingChanges({ isChange: false });
        navigate(ROUTERS_PATHS.DASHBOARD);
    }

    useEffect(() => {
        handleCheckScreenByRole();
    }, [permissions]);
    
    return (
        <>
            <Drawer
                variant="permanent"
                open={isOpenSideBar || isHoverOpenSideBar}
                className={classes.drawerContainer}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <DrawerHeader sx={{ mb: '15px', display: isOpenSideBar || isHoverOpenSideBar ? 'block' : 'none', textAlign: 'center' }}>
                    <img src={ImgLogo} alt="logo" onClick={handleClickLogo} className='logoSideBar'/>
                </DrawerHeader>
                <DrawerHeader
                    sx={{ mb: '15px', display: isHoverOpenSideBar ? 'none' : isOpenSideBar ? 'none' : 'block', textAlign: 'center', minHeight: '48px !important' }}
                >
                    <img src={ImgLogoMini} alt="logoMini" className='logoMini'/>
                </DrawerHeader>
                <List disablePadding sx={{ flexGrow: 1, overflowY: 'auto' }} className="sidebar-inner">
                    {menu.map((item: any) => {
                        if (item.subMenu) {
                            return (
                                <ListItemCollapseWithLink
                                    key={item.title}
                                    data={item}
                                    showText={isOpenSideBar || isHoverOpenSideBar}
                                />
                            );
                        } else {
                            return (
                                <ListItemButtonWithLink
                                    key={item.title}
                                    data={item}
                                    showText={isOpenSideBar || isHoverOpenSideBar}
                                    selected={item.key === 'dashboard' ? pathname === item.href : pathname.includes(item.href)}
                                />
                            );
                        }
                    })}
                </List>
            </Drawer>
        </>
    );
};
export default Sidebar;
