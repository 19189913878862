const ROUTERS_PATHS = {
    LOGIN: '/login',
    NOT_FOUND: '/not-found',
    DASHBOARD: '/',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    PROFILE: '/profile',
    USER_MANAGEMENT_LIST_ROLES: '/user-management/list-roles',
    ALL: '*',
    BANNER_MANAGEMENT: '/banner-management',
    PRODUCT_MANAGEMENT: '/product-management',
    PRODUCT_MANAGEMENT_CREATE_OR_UPDATE: '/product-management/:name/:id',
    PRODUCT_MANAGEMENT_CREATE: '/product-management/:name',
    EMAIL_TEMPLATE: '/email-template',
    CUSTOMER_FEEDBACK_MANAGEMENT: '/customer-feedback-management',
    USER_MANAGEMENT_LIST_USERS: '/user-management/list-users',
    LIST_ARTICLES_MANAGEMENT: '/article-management/list-articles',
    CONFIRMATION_WAITLIST_ARTICLES: '/article-management/confirmation-waitlist-articles',
    AUTHORIZATION: '/authorization',
    ORDER_MANAGEMENT_LIST_REQUEST: '/order-management/list-request',
    ORDER_MANAGEMENT_LIST_ORDER_EXCEL: '/order-management/list-order-from-excel',
};

export default ROUTERS_PATHS;
