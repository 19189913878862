import React from "react";

import ROUTERS_PATHS from "consts/router-paths";
import useAuth from "contexts/useAuth";
import { Navigate } from "react-router-dom";
import { IRoutesState } from "routers/routes";
import { useSelector } from "react-redux";
import { SCREEN_ROLE_CODE } from "consts/permission";

interface GuestGuardProps {
    children: React.ReactNode;
    routes: IRoutesState[];
}

const GuestGuard = (props: GuestGuardProps) => {
    const { children , routes} = props;
    const { isAuthenticated } = useAuth();
    const { permissions } = useSelector((state: any) => state.permission);
    
    const routeAuth = routes?.find((route) => route.routes && route?.routes.length > 0);
    
    if (isAuthenticated) {
        if (permissions && permissions.length && permissions.length > 0) {
            if(permissions.some((p: any) => p.resourceCode === SCREEN_ROLE_CODE.Dashboard)) {
                return <Navigate to={ROUTERS_PATHS.DASHBOARD} replace />;
            } else if (routeAuth && routeAuth.routes) {
                const routeCheck = routeAuth.routes.filter((route) =>
                    permissions.find((permission: any) => route.roles?.includes(permission.resourceCode))
                );
                if (routeCheck && routeCheck.length && routeCheck.length > 0) {
                    return <Navigate to={routeCheck[0].path || ROUTERS_PATHS.DASHBOARD} replace />;
                }
            }
        } else {
            return <Navigate to={ROUTERS_PATHS.AUTHORIZATION} replace />;
        }
    }

    return <>{children}</>;
};

export default GuestGuard;
