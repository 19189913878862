export const COLORS = {
  TEXT_DEFAULT: '#142F27',
  TEXT_SECONDARY: '#255749',
  TEXT_DISABLED: "#614c4c",
  TEXT_INPUT: "#525E6F",
  COLOR_SIDEBAR_ACTIVE: '#FFF',
  COLOR_SIDEBAR: '#142F27',
  BORDER_ERROR_TEXTFIELD: "#d32f2f",
  DISABLED_BORDER_AUTOCOMPLETE: "#ababab",
  SELECT_BACKGROUND_AUTOCOMPLETE: "rgba(0, 0, 0, 0.04)",
  DISABLED_BACKGROUND_AUTOCOMPLETE: "#e7e7e7",
  PLACEHOLDER_TEXT: "#9F9F9F",
  COLOR_TABLE_ACTIVE: "#00000024",
  BACKGROUND_BUTTON: '#347863',
  COLOR_INPUT: '#142F27',
};
