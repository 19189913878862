const API_URLS = {
  //--- Auth
  LOGIN: '/api/Authentication/Login',
  GET_CURRENT_USER: '/api/User/GetCurrentUser',
  REQUEST_FORGOT_PASSWORD: '/api/Authentication/RequestForgotPassword',
  FORGOT_CHANGE_PASSWORD: '/api/Authentication/ConfirmForgotPassword',

  //--- users
  GET_USERS: '/api/User/GetUsers',
  CREATE_USER: '/api/User/CreateUser',
  UPDATE_USER: '/api/User/UpdateUser',
  DELETE_USER: '/api/User/DeleteUser',
  GET_DETAIL_USER: '/api/User/GetUser',
  USER_PROFILE: '/api/User/UpdateProfile',
  CHANGE_PASSWORD: '/api/User/ChangePassword',

  //--- roles
  GET_ROLES: '/api/Role/GetRoles',
  CREATE_ROLE: '/api/Role/CreateRole',
  UPDATE_ROLE: '/api/Role/UpdateRole',
  DELETE_ROLE: '/api/Role/DeleteRole',
  GET_DETAIL_ROLE: '/api/Role/GetRole',
  GET_LOOK_UP_ROLES: '/api/Role/GetLookUpRoles',
  GET_RESOURCE_ACTIONS: '/api/Role/GetResourceActions',

  //--- Upload files
  UPLOAD_FILE: '/api/File/UploadFiles',

  //--- Email Templates
  GET_EMAIL_TEMPLATES: '/api/EmailTemplate/GetEmailTemplates',
  GET_EMAIL_TEMPLATE_BY_ID: '/api/EmailTemplate/GetEmailTemplateById',
  CREATE_EMAIL_TEMPLATE: '/api/EmailTemplate/CreateEmailTemplate',
  UPDATE_EMAIL_TEMPLATE: '/api/EmailTemplate/UpdateEmailTemplate',
  DELETE_EMAIL_TEMPLATE: '/api/EmailTemplate/DeleteEmailTemplate',
  GET_TYPE_EMAIL_TEMPLATE: '/api/EmailTemplate/GetEmailTemplate',
  GET_ALL_TYPE_EMAIL_TEMPLATE: '/api/EmailTemplate/GetEmailTemplateType',

  //--- products
  GET_PRODUCTS: '/api/Product/GetProducts',
  GET_DETAIL_PRODUCT: '/api/Product/GetProduct',
  CREATE_PRODUCT: '/api/Product/CreateProduct',
  UPDATE_PRODUCT: '/api/Product/UpdateProduct',
  DELETE_PRODUCT: '/api/Product/DeleteProduct',
  IMPORT_PRODUCT: '/api/Product/ImportProducts',
  GET_LOOKUP_PRODUCT_CATEGORIES: 'api/public/Product/GetLookUpProductCategories',
  DOWLOAD_TEMPLATE_FILE: '/api/Product/DowloadTemplateFile',
  GET_LOOKUP_PRODUCTS: 'api/Product/GetLookUpProducts',

  //--- Banners
  GET_BANNERS: '/api/Banner/GetBanners',
  DELETE_BANNER: '/api/Banner/DeleteBanner',
  CREATE_BANNER: '/api/Banner/CreateBanner',
  UPDATE_BANNER: '/api/Banner/UpdateBanner',
  GET_DETAIL_BANNER: '/api/Banner/GetBannerById',

  //--- Customer Feedback
  GET_CUSTOMER_FEEDBACKS: '/api/Contact/GetContacts',

  // -- News
  GET_NEWS: '/api/Articles/GetArticles',
  CREATE_NEWS: '/api/Articles/CreateArticle',
  DELETE_NEWS: '/api/Articles/DeleteArticle',
  GET_NEWS_BY_ID: '/api/Articles/GetArticleById',
  REJECT_NEWS: '/api/Articles/RejectedArticle',
  PUBLISH_ARTICLES: '/api/Articles/ApproveArticle',
  GET_ARTICLES_CATEGORIES: '/api/public/Articles/GetLookUpArticleCategories',
  UPDATE_ARTICLE: '/api/Articles/UpdateArticle',
  UN_PUBLISHED: '/api/Articles/UnPublished',
  PUBLISHED_NEWS: '/api/Articles/PublishedArticle',
  SUBMIT_APPROVAL: '/api/Articles/SubmitApproval',
  GET_NEWS_WATTING_APPROVED: '/api/Articles/GetAwaitingApprovalArticles',

  // -- Orders
  GET_ORDERS_REQUEST: 'api/Orders/GetOrderRequests',
  GET_DETAIL_ORDERS_REQUEST: 'api/Orders/GetOrderRequestDetail',
  DELETE_ORDERS_REQUEST: 'api/Orders/DeleteOrderRequest',
  GET_ORDERS: 'api/Orders/GetOrders',
  GET_DETAIL_ORDERS: 'api/Orders/GetOrderDetail',
  DELETE_ORDERS: 'api/Orders/DeleteOrder',
  IMPORT_FILE_ORDERS: 'api/Orders/ImportOrders',
  DOWLOAD_TEMPLATE_ORDERS_FILE: 'api/Orders/DownloadImportOrderTemplate',
  EXPORT_ORDERS_REQUEST_FILE: 'api/Orders/ExportOrderRequest',

  // -- Dashboard
  GET_REVENUE_CHART: 'api/Dashboards/GetRevenueData',
  GET_TOP_SELLING_PRODUCTS: 'api/Dashboards/GetTopSellingProducts',
  GET_NUMBER_SELLING_PRODUCTS: 'api/Dashboards/GetNumberSellingProduct',
  EXPORT_DATA_DASHBOARD: 'api/Dashboards/ExportReport',
};

export default API_URLS;
