import { Box } from "@mui/material";
import { ACTION_ROLE_CODE } from "consts/permission";
import ROUTERS_PATHS from "consts/router-paths";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Permission = (props: any) => {
  const { children, role } = props;

  const { permissions } = useSelector((state: any) => state.permission);
  const filterPermissions = (permissions || []).filter((p: any) => role?.includes(p?.resourceCode));
  const isViewScreen = (filterPermissions || []).some((action: any) => action.actionCode === ACTION_ROLE_CODE.View);
  const isApprovedArticle = (filterPermissions || []).some((action: any) => action.actionCode === ACTION_ROLE_CODE.ApprovedArticle);
  const isScreen = role?.includes(ACTION_ROLE_CODE.ApprovedArticle) ? !isApprovedArticle : !isViewScreen;
  
  if (role && isScreen) { 
    return <Navigate to={ROUTERS_PATHS.AUTHORIZATION} replace />;
  }
  return <Box>{children}</Box>;
};

export default Permission;
