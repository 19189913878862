import { PayloadAction } from "@reduxjs/toolkit";
import { getCurrentUser, login } from "apis";
import { getDetailRoleId } from "apis/roles-service";
import SplashScreen from "components/SplashScreen/index";
import ROUTERS_PATHS from "consts/router-paths";
import STORAGE_KEYS from "consts/storage-keys";
import React, { useCallback, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSetCustomAccordionState } from "redux/accordion/accordion";
import { useSetCheckingChangesState } from "redux/checkingChanges";
import { usePermissionState } from "redux/permission";
import { useSetToastNotificationState } from "redux/toastNotification/toastNotificationSlice";
import { UserInfoReduxType } from "redux/userInfo/types";
import { useCurrentUser } from "redux/userInfo/userInfoSlice";
import routes from "routers/routes";
import { detectUserResponse } from "utils";

const ACCESS_TOKEN_STORAGE_KEY = STORAGE_KEYS.ACCESS_TOKEN;
const USER_STORAGE_KEY = STORAGE_KEYS.USER;

const ACTION_TYPE = {
  INITIALIZED: "INITIALIZED",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

interface InitialAuthStateProps {
  isAuthenticated: boolean;
  isInitialized: boolean;
}

const initialAuthState: InitialAuthStateProps = {
  isAuthenticated: false,
  isInitialized: false,
};

const reducer = (state: InitialAuthStateProps | any, action: PayloadAction<Partial<InitialAuthStateProps>>) => {
  switch (action.type) {
    case ACTION_TYPE.INITIALIZED: {
      const { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
      };
    }
    case ACTION_TYPE.LOGIN: {
      const { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated,
      };
    }
    case ACTION_TYPE.LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = React.createContext({
  ...initialAuthState,
  signIn: (data: any) => Promise,
  signOut: () => Promise,
});

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { showErrorMessageFromResponse } = useSetToastNotificationState();
  const { setCurrentUser, onLogout} = useCurrentUser();
  const { setPermission } = usePermissionState();
  const {setAccordionField} = useSetCustomAccordionState();
  const { setCheckingChanges } = useSetCheckingChangesState();
  const isChangeForm = useSelector((state: any) => state.checkingChanges?.isChange);

  const navigate = useNavigate();
  const signIn = useCallback(
    async (data: { email: string; password: string; onError: (params?: any) => void; onSuccess: (params?: any) => void }) => {
      const { email, password, onError, onSuccess } = data;
      const params = {
        email: email,
        password,
      };
      let user: UserInfoReduxType | null = null;
      try {
        const res: any = await login(params);
        const accessToken = res?.content?.token || "";
        sessionStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
        const resInfor = await getCurrentUser();
        user = detectUserResponse(resInfor);
        setCurrentUser(user);
        // localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
        const permission: any = await getDetailRoleId(user?.roles?.id);
        setPermission(permission?.permissions);
        const resourceCode= (permission?.permissions || []).map((per:any)=>per.resourceCode);
        const findRoutes = routes.find((r) => r.routes);
        const routesPermission = findRoutes?.routes?.find((f) => f.roles?.some((r: string) => resourceCode.includes(r)));
        navigate(routesPermission?.path || ROUTERS_PATHS.DASHBOARD);
        onSuccess();
      } catch (error: any) {
        let errorMessage = "";
        if (!!error && error?.statusCode === 400) {
          errorMessage = "Tên đăng nhập hoặc mật khẩu không chính xác.";
          onError(error);
        } else {
          showErrorMessageFromResponse(errorMessage || error);
        }
      } finally {
        dispatch({
          type: ACTION_TYPE.LOGIN,
          payload: {
            isAuthenticated: !!user,
          },
        });
      }
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    try {
      sessionStorage.clear();
      localStorage.clear();
      setAccordionField('');
      setCheckingChanges({ isChange: false });
      navigate(ROUTERS_PATHS.LOGIN);
      onLogout();
      dispatch({ type: ACTION_TYPE.LOGOUT, payload: {} });
    } catch (error) {
      showErrorMessageFromResponse(error);
    }
  }, [dispatch]);

  const initData = useCallback(async () => {
    try {
      let isAuthenticated = false;
      const accessToken: string = sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) as string;

      if (!!accessToken) {
        const resInfor = await getCurrentUser();
        let user: UserInfoReduxType = detectUserResponse(resInfor);
        setCurrentUser(user);
        const permission: any = await getDetailRoleId(user?.roles?.id);
        setPermission(permission?.permissions);
        isAuthenticated = true;
      }

      setTimeout(() => {
        dispatch({
          type: ACTION_TYPE.INITIALIZED,
          payload: {
            isAuthenticated,
          },
        });
      }, 500);
    } catch (error) {
    }
  }, [dispatch]);

  useEffect(() => {
    initData();
  }, []);

  
  // const handleBeforeUnload = (event: any) => {
  //   isChangeForm && event.preventDefault();
  // };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [isChangeForm]);

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          ...state,
          signOut,
          signIn,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
