export const ERROR_API_MESSAGE: any = {
  1: "Thành công",
  2: "Unauthorized",
  3: "Đang xảy ra sự cố, vui lòng liên hệ với quản trị viên",
  4: "Dữ liệu không tồn tại",
  5: "Tên đăng nhập đã tồn tại",
  6: "Số điện thoại đã tồn tại",
  7: "Email đã tồn tại",
  8: "Mật khẩu không chính xác",
  9: "Xoá không thành công",
  10: "Đã hoạt động",
  11: "This account is already registered",
  12: "Token không chính xác",
  13: "Token hết hạn",
  14: "Dữ liệu đã tồn tại",
  15: "Code đã tồn tại",
  16: "Alias đã tồn tại",
  17: "Dữ liệu đang được sử dụng không thể xoá",
  19: "Dữ liệu đang được sử dụng không thể xoá",
  21: "Cần kích hoạt đơn vị giá",
  BadCredentials: "Mật khẩu không chính xác.",
  AccountNotExist: "Tài khoản không tồn tại.",
  ChangePasswordFailed: "Mật khẩu hiện tại không đúng",
  ProductCodeExist: "Mã sản phẩm đã tồn tại",
  PhoneNumberExist: "Số điện thoại đã được sử dụng rồi.",
  EmailTemplateExist: "Loại email đã được sử dụng rồi.",
  EmailOrPasswordFail: "Sai tên đăng nhập hoặc mật khẩu. Vui lòng kiểm tra lại.",
  EmailNotExist: "Email không tồn tại, vui lòng thử lại sau.",
  EmailExist: "Email đã được sử dụng rồi.",
  UserStatusInactive: "Tài khoản đang bị khóa. Vui lòng liên hệ với quản trị viên hệ thống để được hỗ trợ.",
  EmailTemplateNotExist: "Email template không tồn tại, vui lòng thử lại sau.",
  ArticleRequiredFieldNotEmpty: "Gửi duyệt vui lòng điền đầy đủ các thông tin bắt buộc.",
  RoleExist: "Tên vai trò đã tồn tại.",
  TicketInvalid: "Link đổi mật khẩu đã hết hạn",
  ArticlePublishAtPast: "Ngày xuất bản phải lớn hơn ngày hiện tại.",
  NotHavePermission: "Tài khoản không có quyền truy cập."
};

export const ERROR_MESSAGE = {
  fieldRequired: "Đây là trường bắt buộc.",
  regExpEmail: "Không đúng định dạng email. Định dạng đúng: xxx@xxx",
  maxFileSizeExceed: "Ảnh không được vượt quá",
  maxFileSizeExcel: "Dung lượng file tối đa không được vượt quá",
  wrongFormat: "Vui lòng upload các định dạng",
  wrongFormatFile: "Chỉ chấp nhận các file có định dạng",
  passWordNotMatch: "Xác nhận mật khẩu mới không khớp mật khẩu mới.",
  regPhoneNumber: "Số điện thoại sai định dạng.",
  pleaseEnterNumber: "Vui lòng nhập số.",
  passwordMustBeCharacters: "Mật khẩu phải chứa tối đa 6 ký tự.",
  regexVietNamese: "Không được nhập tiếng Việt có dấu.",
  pleaseTryAgain: "Vui lòng thử lại.",
  errorFileUpload: "Tải tệp lên không thành công. Vui lòng liên hệ với Quản trị viên.",
  noValidPhotos: "Không có ảnh nào hợp lệ",
};


export const MESSAGES_SUCCESS = {
  updateSuccess: "Cập nhật thành công",
  createSuccess: "Thêm mới thành công",
}
