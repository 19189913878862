export const SCREEN_ROLE_CODE = {
    Dashboard: 'Dashboard',
    Users: 'Users',
    Products: 'Products',
    Articles: 'Articles',
    Banners: 'Banners',
    Contact: 'Contacts',
    Introduction: 'Introduction',
    Orders: 'Orders',
    Customers: 'Customers',
    Roles: 'Roles',
    EmailTemplate: 'EmailTemplate',
};

export const ACTION_ROLE_CODE = {
    View: 'View',
    Create: 'Create',
    Update: 'Update',
    Delete: 'Delete',
    Import: 'Import',
    PublishedArticle: 'PublishedArticle',
    UnPublishedArticle: 'UnPublishedArticle',
    RejectedArticle: 'RejectedArticle',
    ApprovedArticle: 'ApprovedArticle',
    Export: 'Export',
};
