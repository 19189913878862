import { combineReducers } from "@reduxjs/toolkit";
import confirmModalSlice from "./confirmModal/confirmModalSlice";
import loadingFullScreenSlice from "./loadingFullScreen/loadingFullScreenSlice";
import toastNotificationSlice from "./toastNotification/toastNotificationSlice";
import userInfoSlice from "./userInfo/userInfoSlice";
import openDrawerSideBarSlice from "./openDrawerSideBar";
import accordionSlice from "./accordion/accordion";
import checkingChangesSlice from "./checkingChanges";
import { permissionSlice } from "./permission";
import highlightSlice from "./highlight/highlight";

const rootReducer = combineReducers({
  [confirmModalSlice.name]: confirmModalSlice.reducer,
  [loadingFullScreenSlice.name]: loadingFullScreenSlice.reducer,
  [toastNotificationSlice.name]: toastNotificationSlice.reducer,
  [userInfoSlice.name]: userInfoSlice.reducer,
  [openDrawerSideBarSlice.name]: openDrawerSideBarSlice.reducer,
  [accordionSlice.name]: accordionSlice.reducer,
  [checkingChangesSlice.name]: checkingChangesSlice.reducer,
  [permissionSlice.name]: permissionSlice.reducer,
  [highlightSlice.name]: highlightSlice.reducer,
});

export default rootReducer;
