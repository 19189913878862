import CloseIcon from "@mui/icons-material/Close";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Box } from "@mui/material";
import { COLORS } from "consts/colors";

const BootstrapDialogStyled = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: '12px',
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const useStyles = makeStyles(() => ({
    dialogTitle: {
        color: `${COLORS.TEXT_DEFAULT}`,
        fontSize: '18px !important',
        fontWeight: '700 !important',
        lineHeight: '22px !important',
        display: "flex",
        alignItems: "center",
        fontFamily: 'Inter !important'
    },
    dialogTitleBackGround: {
        background: '#F9FAF9',
    }
}));

interface BootstrapDialogTitleProps extends DialogTitleProps {
    children: React.ReactNode;
    onClose: (params?: any) => void;
    isConfirm?: boolean;
    iconTitle?: React.ReactNode;
}

const BootstrapDialogTitle = (props: BootstrapDialogTitleProps) => {
    const { children, onClose, isConfirm, iconTitle, ...other } = props;
    const classes = useStyles();

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} className={clsx(classes.dialogTitle, { [classes.dialogTitleBackGround]: !isConfirm })}>
            {iconTitle && <Box sx={{ marginRight: "16px", display: 'flex' }}>{iconTitle}</Box>}
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: '#000000',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

interface BootstrapDialogProps extends DialogProps {
    children: React.ReactNode;
    formTitle: string;
    handleClose: (params?: any) => void;
    handleSave: (params?: any) => void;
    isOpen: boolean;
    isForm?: boolean;
    classNameContainer?: any;
    isConfirm?: boolean;
    iconTitle?: React.ReactNode;
}

const BootstrapDialog = (props: BootstrapDialogProps) => {
    const { children, formTitle, handleClose, isOpen, isForm, handleSave, classNameContainer, isConfirm, iconTitle, ...dialogProps } = props;

    return (
        <BootstrapDialogStyled {...dialogProps} open={isOpen} className={classNameContainer}>
            <BootstrapDialogTitle onClose={handleClose} isConfirm={isConfirm} iconTitle={iconTitle}>{formTitle}</BootstrapDialogTitle>
            {isForm ? <form onSubmit={!!handleSave ? handleSave : undefined}>{children}</form> : children}
        </BootstrapDialogStyled>
    );
};

export default BootstrapDialog;
