import ELEMENT_ID from "consts/element-ids";
import { useGetConfirmModalState, useSetConfirmModalState } from "redux/confirmModal/confirmModalSlice";

import CrudModal from "components/CrudModal";
import { convertBreakToHtml } from "utils";
import { makeStyles } from "@mui/styles";
import { Icons } from "consts/svg-icons";
import { useEffect, useMemo, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles(() => ({
    dialogContainer: {
        "& .MuiPaper-root": {
            maxWidth: '500px !important',
            '& .MuiTypography-h6': {
                padding: "16px 16px 0px 16px !important",
                color: '#000000 !important'
            }
        }
    },
    dialogContent: {
        padding: "0px 16px 16px 16px !important",
    },
}));

const ConfirmModal: () => JSX.Element = () => {
    const classes = useStyles();
    const { isOpen, title, message, cancelBtnLabel, okBtnLabel, isDeleteConfirm, isNoAction, btncolor, iconTitle } =
        useGetConfirmModalState();
    const { closeConfirmModal } = useSetConfirmModalState();
    const [isActionModal, setIsActionModal] = useState<boolean>(true);
    const onOk = () => {
        const el = document.getElementById(ELEMENT_ID.CONFIRM_MODAL_OK_BUTTON);
        if (el) {
            el.click();
        }
        closeConfirmModal();
    };

    const iconTitleModal = useMemo(() => {
        let _iconTitleModal = <Icons.IconInfo />;
        switch (iconTitle) {
            case 'checkCircle':
                _iconTitleModal = <CheckCircleIcon sx={{color: '#15c73d'}}/>
                break;
        }
        return _iconTitleModal
    }, [iconTitle])

    useEffect(() => {
        if(isNoAction) {
            setTimeout(() => {
                setIsActionModal(true);
            }, 3000);
        } else {
            setIsActionModal(false);
        }
    }, [isNoAction])
    
    return (
        <>
            <CrudModal
                isOpen={isOpen}
                handleClose={closeConfirmModal}
                handleBtn={!isDeleteConfirm ? onOk : undefined}
                cancelBtnLabel={cancelBtnLabel}
                btnLabel={okBtnLabel}
                btncolor={btncolor}
                formTitle={title}
                iconTitle={iconTitleModal}
                contentClassName={classes.dialogContent}
                classNameContainer={classes.dialogContainer}
                isNoAction={isActionModal}
                noCheckingChanges
                dialogProps={{
                    fullWidth: true,
                    maxWidth: "sm",
                }}
                isConfirm
            >
                <div
                    style={{
                        padding: "16px 0",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: convertBreakToHtml(message),
                    }}
                ></div>
            </CrudModal>

            <div id={ELEMENT_ID.CONFIRM_MODAL_OK_BUTTON} hidden></div>
        </>
    );
};

export default ConfirmModal;
