import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { COLORS } from "consts/colors";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAccordionState, useSetCustomAccordionState } from "redux/accordion/accordion";
import { useSetConfirmModalState } from "redux/confirmModal/confirmModalSlice";
import { NonDecorationLink } from "styled/sidebar";
import styles from "./styles.module.scss";
import classNames from "classnames";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { SideBarMenu } from "../type";
import { makeStyles } from "@mui/styles";
import { useSetCheckingChangesState } from "redux/checkingChanges";

interface ListItemProps {
  showText?: boolean;
  data: SideBarMenu;
}

const useStyles = makeStyles(() => ({
  itemButtonMini: {
    display: 'flex !important',
    justifyContent: 'center !important'
  }
}))

const ListItemCollapseWithLink = ({ showText, data }: ListItemProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const isChangeForm = useSelector((state: any) => state.checkingChanges?.isChange);
  const { setCheckingChanges } = useSetCheckingChangesState();
  const { openConfirmModal } = useSetConfirmModalState();
  const {setAccordionField} = useSetCustomAccordionState();
  const result = useGetAccordionState();
  const { itemAccordion } = result;

  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    const isMatch = data.subMenu && data.subMenu.some((item: any) => item.href && pathname?.includes(item.href));
    if (isMatch) {
      setAccordionField(data.key);
    }
  }, [pathname]);

  const handleCheckFormChanges = (href: string) => {
    if (isChangeForm) {
      openConfirmModal({
        title: 'Cảnh báo',
        message: 'Bạn có chắc chắn muốn rời khỏi trang này không? Dữ liệu bạn đã nhập sẽ không được lưu.',
        isOpen: true,
        cancelBtnLabel: 'Hủy',
        okBtnLabel: 'Đồng ý',
        isCloseCase: false,
        onOk: () => onNavigate(href),
      });
    }
  };

  const onNavigate = (href: string) => {
    setCheckingChanges({ isChange: false });
    navigate(href, { replace: false });
  };
  const handleChange = () => {
    if (data.key === itemAccordion) {
      setAccordionField('');
    } else {
      setAccordionField(data.key);
    }
  }
  
  return (
    <>
      <Accordion expanded={data.key === itemAccordion} onChange={handleChange} className={classNames(styles.accordion)}>
        <AccordionSummary className={styles.accordionSummary} sx={{
          '& .MuiAccordionSummary-content': {
            margin: '0 !important'
          }
        }}>
          <ListItemButton sx={{
            backgroundColor: (data.key === itemAccordion && !showText) ? `${COLORS.BACKGROUND_BUTTON} !important` : '#FFFF !important',
            padding: '10px 10px',
            borderRadius: '8px',
            width: showText ? 'auto' : '50px'
          }} className={classNames({[classes.itemButtonMini]: !showText})}>
            <ListItemIcon
              sx={{
                mr: showText ? 1 : 0,
                justifyContent: {
                  xs: "flex-start",
                  sm: "center"
                },
                minWidth: '25px !important'
              }}
            >
              {data.key === itemAccordion && !showText ? data.iconActive : data.icon}
            </ListItemIcon>
            {showText && <ListItemText
              sx={{
                opacity: showText ? 1 : 0,
                color: (data.key === itemAccordion && !showText) ? COLORS.COLOR_SIDEBAR_ACTIVE : COLORS.COLOR_SIDEBAR,
                "& span": {
                  fontWeight: (data.key === itemAccordion && !showText) ? '700 !important' : '500 !important'
                }
              }}
            >
              {t(data.title)}
            </ListItemText>}

            {data.subMenu && showText ? (data.key === itemAccordion ? <ExpandMore /> : <NavigateNextIcon />) : null}
          </ListItemButton>
        </AccordionSummary>
        <AccordionDetails className={classNames(styles.accordionDetails)}>
          {showText && <>
            {data.subMenu &&
              data.subMenu.map((child: any) => (
                <List disablePadding key={child.title}>
                  <NonDecorationLink to={isChangeForm ? "#" : child.href as string} onClick={() => { handleCheckFormChanges(child.href as any) }}>
                    <ListItemButton
                      sx={{
                        paddingLeft: showText ? '43px' : 3,
                        backgroundColor: pathname?.includes(child.href) ? `${COLORS.BACKGROUND_BUTTON} !important` : '#FFFF !important',
                        margin: '0 10px',
                        borderRadius: '8px'
                      }}
                      selected={pathname?.includes(child.href)}
                    >
                      <ListItemText
                        sx={{
                          color: pathname?.includes(child.href) ? COLORS.COLOR_SIDEBAR_ACTIVE : COLORS.COLOR_SIDEBAR,
                          "& span": {
                            fontWeight: pathname?.includes(child.href) ? '700 !important' : '500 !important'
                          }
                        }}
                      >
                        {t(child.title)}
                      </ListItemText>
                    </ListItemButton>
                  </NonDecorationLink>
                </List>
              ))}
          </>}
        </AccordionDetails>

      </Accordion>
    </>
  );
}

export default ListItemCollapseWithLink;
