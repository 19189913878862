import axios from 'axios';
import API_URLS from "consts/api-url";
import ROUTERS_PATHS from "consts/router-paths";
import SESSION_KEYS from "consts/storage-keys";

const requestHandler = (request: any) => {
  const token = globalThis.sessionStorage.getItem(SESSION_KEYS.ACCESS_TOKEN);
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
  },
  timeout: Number(process.env.NEXT_PUBLIC_TIME_OUT) || 5000,
  validateStatus: function (status: number) {
    return status >= 200 && status < 300;
  },
});

axiosInstance.interceptors.request.use((request: any) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response: any) => {
    return response?.data;
  },
  (error: any) => {
    const statusCode = error?.response?.status;
    const apiUrl = error?.config?.url;
    const excludeAPI = [API_URLS.LOGIN];
    if (statusCode === 401 && !excludeAPI.includes(apiUrl)) {
      alert("Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại.");
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = ROUTERS_PATHS.LOGIN;
    } else if (statusCode === 403) {
      window.location.href = ROUTERS_PATHS.AUTHORIZATION;
    }
    return Promise.reject(error?.response
      ? error.response.data
      : error?.message === 'ExpiredTime'
        ? {
          errorType: 'ExpiredTime',
          errorMessage: 'Expired Time'
        }
        : {
          errorType: 'UnhandledException',
          errorMessage: 'Tình huống ngoại lệ không thể xử lí được'
        })
  }
);

export default axiosInstance;
